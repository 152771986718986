export default function IconArrowUp() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 10 10"
      id="Arrows-Button-Up--Streamline-Micro"
      height="12"
      width="12"
    >
      <desc>{'Arrows Button Up Streamline Icon: https://streamlinehq.com'}</desc>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M0.5 7.25 4.646 2.9a0.5 0.5 0 0 1 0.708 0L9.5 7.25"
        strokeWidth={1}
      />
    </svg>
  );
}
